<template>
    <div class="page-success">
        <div class="top flex justify-between items-center q-pa-md bg-white" style="border-bottom: 1px solid #D4D4D4">
            <h1 class="typography-h1">{{ $t('payment_success.title') }}</h1>
            <q-btn class="my-btn my-btn--secondary gt-sm" outline :label="$t('storefront.lang_other')" @click="toggleLanguage" />
            <q-btn class="my-btn my-btn--secondary lt-md" outline :label="$t('storefront.lang_other__mobile')" @click="toggleLanguage" />
        </div>
        <div class="q-ma-md bg-white q-px-lg q-py-xl" style="border: 1px solid #D4D4D4">
            <template v-if="info">
                <div v-if="color" class="q-mb-md">
                    <q-chip class="my-chip" dense :color="color" text-color="white" :label="label" />
                </div>
                <div>
                    <span class="small-label">{{$t('label.event_title')}}</span>
                    <div class="typography-h2">
                        {{ loc(info.name) }}
                    </div>
                </div>
                <div class="typography-h3 text-grey-5 q-mb-md" v-if="info.subtitle">{{ info.subtitle }}</div>
                <div class="flex">
                    <div class="q-mb-lg">
                        <span class="small-label">{{$t('label.location')}}</span>
                        <div class="description">
                            {{ info.location }}
                        </div>
                    </div>
                    <div class="q-mb-lg q-ml-lg" v-if="info.date">
                        <span class="small-label">{{$t('label.event_date')}}</span>
                        <div class="description">
                            {{ info.date }}
                        </div>
                    </div>
                </div>

                <div class="info" v-if="info.seller">
                    <span class="small-label block q-mb-sm">{{ $t('label.organizer') }}</span>
                    <div class="description">{{info.seller}}</div>
                </div>
            </template>

            <div class="flex justify-center items-center">
                <q-icon class="confirm" size="32" name="svguse:/icons.svg#check-thin"></q-icon>
                <h2 class="typography-h1 title q-ml-lg">{{ $t('payment_success.heading') }}</h2>
            </div>
            <p class="text-center detail q-mt-lg">{{ $t('payment_success.text') }}</p>
            <div class="flex justify-center q-mt-xl">
                <a href="https://meetfactory.cz" style="text-decoration: none">
                    <q-btn class="my-btn my-btn--primary" icon-right="svguse:/icons.svg#arrow-right" color="black" :label="$t('payment_success.link')" @click="" />
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data:()=>({
        info:null
    }),
    async created() {
        this.$i18n.locale = this.$route.params.lang;
        if(localStorage && localStorage.getItem('successItem')){
            this.info = JSON.parse(localStorage.getItem('successItem'));
            localStorage.removeItem('successItem');
        }
        // const temp = `{ "name": { "cs": "Kovářův pravnuk", "en": "The Blacksmith's Grandson" }, "date":"12-21-1222", "location": "Divadlo Granaharafafa", "seller": "Československá divadelní společnost", "type": "koncert" }`;
        //
        // if(!localStorage.getItem('successItem')){
        //     this.info = JSON.parse(temp);
        // }
    },

    meta() {
        return {
            title: this.$t('payment_success.title'),
        };
    },
    methods:{
        toggleLanguage() {
            this.$i18n.locale = this.$i18n.locale === 'en' ? 'cs' : 'en';
            this.$router.replace({params: {lang: this.$i18n.locale}}); // had to manually switch locale
        },
    },
    computed: {
        color() {
            if(!this.info) return false;
            switch (this.info.type) {
                case 'koncert': return 'blue';
                case 'multikoncert': return 'cyan';
                case 'festival': return 'green';
                case 'divadlo': return 'red';
                case 'vystava': return 'purple';
                case 'ostatni': return 'brown';
            }
        },
        label() {
            return this.$tc(`event.type.${this.info.type}`, 1);
        },
    }
};
</script>
<style scoped lang="scss">
.page-success{
    background-color: $black-50;
    min-height: 100vh;
}
.confirm{
    background: $green-100;
}
.detail{
    color: $black-600;
}
.info{
    position: relative;
    padding-bottom: 36px;
    margin-bottom: 56px;
    &:after{
        content: '';
        position: absolute;
        bottom: -1px;
        left: -24px;
        width: calc(100% + 48px);
        height: 1px;
        background: $black-200;
    }
}

@media  (max-width: 768px){
    .page-success .typography-h1.title{
        text-align: center;
        margin-top: 24px;
        margin-left: 0;
    }
    .page-success .top{
        flex-wrap: nowrap;
    }
}

</style>
